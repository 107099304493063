import {FC, useEffect, useRef} from "react";
import {usePlayGames} from "../../../features/play_games/usePlayGames";

const HTMLGameFrame: FC = () => {
    const iframeRef = useRef<HTMLIFrameElement>(null);
    const {gameSession} = usePlayGames();

    useEffect(() => {
        if (gameSession && iframeRef.current) {
            const iframe = iframeRef.current;
            iframe.onload = () => {
                const iframeDoc = iframe.contentDocument || iframe.contentWindow?.document;
                if (iframeDoc && gameSession.SessionHTML !== null) {
                    iframeDoc.open();
                    iframeDoc.write(gameSession.SessionHTML);
                    iframeDoc.close();
                }
            };
        }
    }, [gameSession]);


    return (
        <iframe
            style={{
                width: '100%',
                height: '100%',
                border: 'none',
                margin: '0',
                padding: '0',
                zIndex: 1000
            }}
            ref={iframeRef}
            title="Game Content"
        />
    );
}

export default HTMLGameFrame;